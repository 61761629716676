import React, { Component } from 'react'
export class PrivacyPolicy extends Component {
    render() {
        return (
            <>
                <div
                    className='container'
                    style={{ paddingTop: this.props.top ?? '15rem', paddingBottom: '10rem' }}
                >
                    <div style={{ wordWrap: 'break-word' }}>
                        <div className='WordSection1'>
                            <h1 style={{ textAlign: 'justify' }}>
                                <span style={{ fontSize: '36.0pt', lineHeight: '107%' }}>
                                   Política de privacidad de la aplicación: ENDOFORCE
                                </span>
                            </h1>

                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                  <span>&nbsp;</span>
                                </p>

                                <h2 style={{ textAlign: 'justify' }}>
                                  <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                  </p>
                                  <span style={{ fontSize: '15.0pt', lineHeight: '107%' }}>
                                      Esta Política de privacidad se actualizó el 03 de octubre de 2024.
                                  </span>
                                </h2>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <h4 style={{ textAlign: 'justify' }}>
                                <span style={{ fontSize: '20.0pt', lineHeight: '107%' }}>
                                    1. Introducción
                                </span>
                            </h4>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Compartimos esta política de privacidad para ser totalmente transparentes
                                    con el uso de los datos y privacidad de los mismos, así podemos ofrecer una
                                    experiencia segura a todos nuestros usuarios. Esta política describe cómo recopilamos
                                    y utilizamos información personal, responde a cómo los recogemos, usamos y con quién
                                    compartimos los datos del usuario. Toda la información personal se ha preparado
                                    bajo la legislación vigente para comunicar que nosotros, la empresa ENDOCIENCIA S.A.S,
                                    en conjunto con la empresa LINKCODE S.A.S, empresas desarrolladoras del videojuego
                                    están en cumplimiento de lo dispuesto en la Ley 1581 de 2012 y sus decretos reglamentarios
                                    para notificar que son responsables de esta política de privacidad del juego ENDOFORCE.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Al acceder usar nuestra Aplicación, acepta los términos de esta Política de
                                    privacidad. Si es menor de edad en el país o región en la que se reside, está confirmando que
                                    su padre o guardián ha revisado y aceptado los términos de esta política de privacidad. Si
                                    usted o su padre o guardián no acepta cualquiera de los términos de esta Política de privacidad,
                                    debe dejar de usar la Aplicación inmediatamente.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Cuando usted utiliza cualquier sección de ENDOFORCE, reconoce que ENDOFORCE puede recopilar, usar,
                                    y divulgar su información tal y como se describe en esta Política de Privacidad.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <h4 style={{ textAlign: 'justify' }}>
                                <span style={{ fontSize: '20.0pt', lineHeight: '107%' }}>
                                    2. Sobre los creadores
                                </span>
                            </h4>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    ENDOCIENCIA S.A.S es una empresa Colombiana dedicada a prestar servicios de
                                    salud especializada en el campo de endocrinología pediátrica, identificada con
                                    NIT No. 901.133.184-6 y domicilio en la Cra 16 N 84a - 09 Consultorio 424 de la
                                    ciudad de Bogotá, D.C.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    LINKCODE S.A.S es una empresa Colombiana dedicada a prestar servicios de
                                    desarrollo especializados en aplicaciones móviles y videojuegos, identificada con
                                    NIT No. 901.042.275-7 y domicilio en la Carrera 20 N 8 - 31 de la
                                    ciudad de Bogotá, D.C.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    ENDOCIENCIA S.A.S Y LINKCODE S.A.S unen sus especialidades para crear el
                                    videojuego de ENDOFORCE. En materia de responsabilidad de los datos recopilados
                                    ambas empresas son responsables de garantizar la privacidad y confidencialidad de los
                                    datos y la información que usted nos proporcione, la cual se tratará
                                    con la mayor seguridad posible, y sólo se utilizará de acuerdo con los
                                    límites establecidos en esta política de privacidad. Asimismo,
                                    ambas compañias pone en su conocimiento el derecho que le asiste de conocer,
                                    actualizar, rectificar o solicitar la supresión de sus datos personales,
                                    solicitar la prueba de la autorización otorgada, así como el derecho
                                    a solicitar prueba de la autorización otorgada, revocarla y ser informados
                                    sobre el uso dado a los datos personales, consultar de forma gratuita
                                    los datos personales previamente suministrados y acudir ante la
                                    Superintendencia de Industria y Comercio cuando no se atienda en
                                    debida forma sus consultas o reclamos en materia de protección de
                                    datos personales.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Para los derechos anteriormente expuestos, se acuerda que ENDOCIENCIA sera el
                                    principal canal de contacto para la atención al cliente y que el Titular de la mencionada
                                    información tendrá a su disposición los siguientes canales de atención
                                    a sus solicitudes en materia de Habeas Data:  endociencia.sas@gmail.com
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <h4 style={{ textAlign: 'justify' }}>
                                <span style={{ fontSize: '20.0pt', lineHeight: '107%' }}>
                                    2. INFORMACIÓN QUE RECOPILAMOS SOBRE USTED
                                </span>
                            </h4>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <h4 style={{ textAlign: 'justify' }}>
                                <span style={{ fontSize: '14.0pt', lineHeight: '107%' }}>
                                    2.1 Información que usted nos proporciona
                                </span>
                            </h4>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Podemos recoger las siguientes categorias de información sobre usted o su uso de Endoforce:
                                    (1) Identificadores (como nombre y dirección de correo electrónico);
                                    (2) Información comercial (como datos de transacciones);
                                    (3) Datos financieros (como información de pago);
                                    (4) Actividad de internet (como identificación si está o no conectado a una red)
                                    (5) Clasificaciones protegidas por la ley (como el género)
                                    (6) Información médica (como información que nos proporcione voluntariamente como su hora de ir a dormir);
                                    (7) Información para atención al cliente (cuándo acceda a nuestros canales de comunicación y proporcione sus datos u observaciones para atender su caso);
                                    (8) Otra identificación que le identifique o que pueda asociarse con usted de manera razonable.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '15.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    Datos de forma directa:
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    No es necesario crear una cuenta para acceder a algunas funciones de ENDOFORCE. En caso de
                                    que si cree una cuenta, le pediremos:
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    (1) Información de registro (Nombre, dirección de correo electrónico y contraseña);
                                    (2) Datos de identidad (Fecha de nacimiento y género);
                                    (3) Datos de perfil de jugador (Nombre de usuario y hora en la que comunmente se va a dormir)
                                 </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Si se suscribe a alguno de nuestras subscripciones le solicitaremos información adicional que puede
                                    incluir nombre completo y/o detalles de su forma de pago.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    NOTA: No utilice información personal que tenga un compromiso civil como el número de identificación del
                                    documento personal de su nacionalidad u otros datos sensibles que puedan dar más información que la solicitada.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <h4 style={{ textAlign: 'justify' }}>
                                <span style={{ fontSize: '14.0pt', lineHeight: '107%' }}>
                                    2.1 Información que recopilamos de forma pasiva o automática
                                </span>
                            </h4>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    (1) Datos de la operación: Recopilaremos información relacionada con sus operaciones
                                    de compra de subscripciones a través de nuestra Aplicación, como la fecha y la hora,
                                    los valores de la subscripción y otros detalles de la operación
                                </span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    (2) Información incluida en la correspondencia:
                                    Recopilaremos la información incluida en la correspondencia que mantenga con nosotros, como
                                    su nombre, dirección, correo electrónico, o número de teléfono. Por ejemplo, cuando usted se
                                    pone en contacto con nosotros para solicitar los servicios al cliente, mantenemos un registro
                                    de dicha correspondencia.
                                </span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    (3) Datos técnicos:
                                    Recopilaremos ciarta información sobre el uso que haga de nuestra Aplicación y sobre
                                    el dispositivo que utilice para acceder a ella, aun cuando no haya creado una cuenta ni
                                    se haya coenctado. Por ejemplo, recibimos datos sobre los juegos en los que participa y su
                                    desempeño en los mismos. Dicha información puede incluir datos sobre el dispositivo (como el
                                    modelo, información del sistema operativo, etc.), los identificadores únicos de los jugadores,
                                    como el nombre de usuario y la contraseña. Información de diversas estadísticas como el número
                                    de sesiones, avance del juego, datos relativos al desempeño de cada juego, incluidos los registros
                                    de notificaciones relacionados a la actividad de sueño (Abrir la aplicación a determinadas horas) o
                                    la captura de dibujos realizados por los jugadores. En la información técnica también aplica el registro
                                    de errores del juego, las compras de subscripciones y otros registros relacionados a la frecuencia de
                                    uso de la aplicación.                                
                                </span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Es posible que esta información sea recopilada por un proveedor de servicios analíticos externo en nuestro
                                    nombre o mediante cookies o tecnologías similares para ayudarnos a recoger y analizar la información. (Más adelante
                                    ahondamos en el tema)
                                </span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    (4) Permisos de la Aplicación: Es posible que solicitemos permiso para acceder a aplicaciones y a determinadas
                                    funciones de su dispositivo.
                                </span>
                            </p>

                            <h4 style={{ textAlign: 'justify' }}>
                              <span style={{ fontSize: '14.0pt', lineHeight: '107%' }}>
                                2.2 ¿Cuándo se aplica esta política de privacidad?
                              </span>
                            </h4>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>
                              Esta política de privacidad describe cómo recopilamos y utilizamos sus datos personales cuando utiliza Nuestros
                              servicios, juega Nuestras aplicaciones o interactúa con Nosotros. Se aplica a todos los visitantes de Nuestros
                              sitios web, usuarios finales (incluidos usuarios que pagan y no pagan, adultos y niños) y Nuestros canales de redes sociales.
                              </span>
                            </p>



                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <h4 style={{ textAlign: 'justify' }}>
                                <span style={{ fontSize: '20.0pt', lineHeight: '107%' }}>
                                    3. CÓMO UTILIZAMOS SU INFORMACIÓN Y DESTINATARIOS DE LA MISMA
                                </span>
                            </h4>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>
                                Recopilamos la siguiente información suya cuando instala y/o utiliza estas aplicaciones en
                                plataformas como Apple AppStore, Google Play Store, Amazon, Samsung, etc.
                              </span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>&nbsp;</span>
                            </p>



                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Usaremos las catergorias de información mencionadas en el anterior apartado
                                    para operar y proporcionarle ENDOFORCE. Esto incluye, entre otros, los siguientes fines
                                    técnicos, empresariales y comerciales:
                                </span>
                            </p>
                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    (1) Dar acceso a nuestra Aplicación: Para proporcionarle acceso a nuestra Aplicación
                                    de manera cómoda, óptima y permitirle llevar un registro de su partida en nuestra Aplicación.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    (2) Procesar y facilitar las transacciones con nosotros: Usarmenos su información para procesar
                                    las transacciones y los pagos, y para recaudar y cobrar el dinero que se nos deba.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    (3) Procesar y facilitar las transacciones con nosotros: Usarmenos su información para procesar
                                    las transacciones y los pagos, y para recaudar y cobrar el dinero que se nos deba.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    (4) Prestar el servicio de atención al cliente: Para brindar atención, gestionar las consultas y
                                    las quejas en relación con ENDOFORCE y compartir su información personal con los desarrolladores de
                                    nuesta Aplicación, el proveedor de soporte informático y el proveedor de servicios de pago, siempre que
                                    sea necesario para prestar el servicio de atención al cliente.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    (5) Enviar notificaciones automáticas: Para enviarle notificaciones automáticas sobre el uso que hace
                                    de ENDOFORCE, nuestras noticias o recordatorios programados, siempre que usted nos haya dado su permiso
                                    para hacerlo.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    (6) Elaborar análisis: Para realizar un análisis de datos que nos permita mejorar ENDOFORCE, los
                                    servicios de los creadores, el marketing, las relaciones con el cliente y las experiencias.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    (7) Cumplir con políticas, procedimientos y leyes: Para poder cumplir con nuestras políticas y
                                    procedimientos o compartir su información con nuestros asesores técnicos y jurídicos, así como cumplir
                                    con la ley, o responder a solicitudes legítimas y procesos legales, como, por ejemplo, para cumplir con
                                    una orde o citación judicial.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <h4 style={{ textAlign: 'justify' }}>
                                <span style={{ fontSize: '20.0pt', lineHeight: '107%' }}>
                                    4. CON QUIÉN PODEMOS COMPARTIR SU INFORMACIÓN
                                </span>
                            </h4>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Estamos comprometidos con proteger su privacidad y, por lo tanto, no proporcionamos sus
                                    datos personales a terceros para sus fines comerciales.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Es posible que compartamos las categorias de información personal identificadas en secciones previas
                                    con fines empresariales (tal como los define la legislación correspondiente) o si así lo exige la ley
                                    correspondiente. Más concretamente, es posible que compartamos su información personal en las siguientes
                                    situaciones:
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>
                              Tenga en cuenta que nuestro sitios web puede contener enlaces a sitios web de otras empresas.
                              Si sigue un enlace a cualquiera de estos sitios web, debe tener en cuenta que tienen sus propias prácticas
                              de privacidad y que no asumimos ninguna responsabilidad por el procesamiento de sus datos personales. Por
                              lo tanto, asegúrese de leer su política de privacidad.
                              </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    (1) Proveedores de servicio y agentes: Es posible que compartamos su información con ciertos
                                    terceros que nosotros seleccionamos para que nos ayuden a apoyar nuestras operaciones. Estos
                                    incluyen, por ejemplo, servicios que nos ayuden a procesar pagos, enviar correos electrónicos,
                                    entre otros. Dichos terceros podrán tener acceso solo con el fin de llevar a cabo tareas en
                                    nuestra representación y nosotros celebramos un contrato con ellos mediante el cual se obligan
                                    a proteger su información de conformidad con la presente Política de Privacidad
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    (2) Posibles vendedores y compradores de nuestro negocio: En relación con una fusión, adquisición,
                                    concurso de acreedores, disolución, transacción o procedimiento que implique la venta, cesión,
                                    desinversión o revelación de una parte o de la totalidad de nuestra empresa o activos, o durante
                                    la negociación de cualquiera de ellos.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    (3) Cumplimiento de la ley: Es posible que divulguemos su información cuando creamos de buena fe que
                                    asi nos lo exige la ley o en respuesta a un citatorio, mandato judicial u otro mecanismo legal. Asímismo,
                                    es posible que divulguemos la información cuando creamos de buena fe que dicha divulgación puede evitar un
                                    fraude y el abuso de ENDOFORCE o de sus usuarios para proteger nuestros derechos de propiedad.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <h4 style={{ textAlign: 'justify' }}>
                              <span style={{ fontSize: '20.0pt', lineHeight: '107%' }}>
                                5. PERMISOS DE DISPOSITIVOS
                              </span>
                            </h4>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>
                                Algunos de nuestros juegos solicitarán permisos de dispositivos para poder operar algunas de las actividades
                              </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>
                                (1) Se requiere acceso a la cámara para los videojuegos de Endoforce que requieran,
                                  un registro de retos como la toma de agua, entre otros. Los datos se procesan de forma efímera.
                                  Puede desactivar esta
                                funcionalidad yendo al Área principal y anulando la selección del permiso ”Access camera”.
                              </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>
                              (2) Las actividades de Endocolor, de Endoforce también tienen una funcionalidad de instantánea
                              que te permite guardar capturas de pantalla de los dibujos realizados. Las capturas de pantalla
                              se guardan solo en el dispositivo y El Proveedor las almacena. Puede desactivar esta funcionalidad
                              yendo al Área principal y deseleccionando el permiso “Guardar instantáneas”.
                              </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>
                              (3) Se requieren los permisos de micrófono, cámara y reconocimiento de voz
                              para mejorar la experiencia de la aplicación en los videojuegos de control de respiración de Endoforce. Estos datos solo se
                              usan como parte de la experiencia del juego y no se usan para la creación de contenido. Estos datos se
                              procesan de forma efímera y sólo se guardan localmente en el dispositivo del usuario.
                              </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>
                              (4) La funcionalidad de captura de pantalla se puede encontrar en algunos de los minijuegos.
                              Las capturas de pantalla solo se guardan localmente en el dispositivo y puedes eliminarlas en cualquier
                              momento eliminándolas de tu galería de capturas de pantalla.
                              </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>&nbsp;</span>
                            </p>


                            <h4 style={{ textAlign: 'justify' }}>
                                <span style={{ fontSize: '20.0pt', lineHeight: '107%' }}>
                                    6. USO DE MÓDULOS DE RECOPILACIÓN DE INFORMACIÓN DE TERCEROS
                                </span>
                            </h4>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Podremos usar el Módulo de recopilación de la información de un tercero para investigar las tendencias
                                    en el uso de ENDOFORCE, operar de forma eficaz con la Aplicación, prevenir estafas e insertar publicidad para
                                    los clientes. En este caso, las organizaciones que se detallan a continuación podrán solicitar información para dicho
                                    fin. Consulte también la política de privacidad de la empresa correspondiente
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '15.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    Notificaciones:
                                </span>
                            </p>
                       

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Google LLC (Google Firebase)
                                    <a href="https://policies.google.com/privacy">Política de privacidad</a>
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>
                                Trabajamos con ByteBrew como proveedor de atribución para ayudarnos a comprender sus interacciones con
                                las aplicaciones y optimizar y analizar campañas publicitarias móviles. ByteBrew no recopila ninguna
                                información de identificación personal sobre usted.
                              </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <h4 style={{ textAlign: 'justify' }}>
                                <span style={{ fontSize: '20.0pt', lineHeight: '107%' }}>
                                    7. COOKIES Y SEGUIMIENTO
                                </span>
                            </h4>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    ENDOFORCE no hace uso de cookies, balizas web, identificadores de dispositivos y otro mecanismos de seguimiento.
                                    Nuestro interes son los hábitos saludables y seguimiento de datos que usted nos autoriza con esta política de privacidad.
                                    Potencialmente algúnos de nuestros proveedores puede usar servicios de cookies y seguimiento.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            

                            <h4 style={{ textAlign: 'justify' }}>
                                <span style={{ fontSize: '20.0pt', lineHeight: '107%' }}>
                                    8. CÓMO CUIDAMOS DE SU INFORMACIÓN Y DURANTE CUÁNTO TIEMPO LA CONSERVAMOS
                                </span>
                            </h4>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Aplicamos las medidas de seguridad tecnológicas y operativas apropiadas para proteger su información contra un
                                    acceso no autorizado o un uso ilegítimo.
                                </span>
                            </p>

                   
                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Conservaremos su información tanto tiempo como sea conveniente para  prestarle los servicios que nos haya solicitado,
                                    o tanto tiempo como sea razonablemente necesario para nuestros fines comerciales legítimos, así como a efectos de aplicación
                                    y de cumplimiento de la ley, o para resolver conflictos. Los periodos de conversación reales dependen de diversos factores, como
                                    el tipo de subscripción, el tipo y la sensibilidad de los datos, las necesidades comerciales, las expectativas y necesidades del
                                    cliente, datos que consideramos sin actividad, los tipos de controles al alcance del usuario y la forma en la que se almacenan los datos.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                  Con el fin de brindar atención y servicios al cliente, conservaremos sus datos personales durante el tiempo que sea necesario, a menos que se comunique
                                  con nosotros para solicitar una eliminación.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '15.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    Revisión de la cuenta
                                </span>
                            </p>
                  
                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Puede acceder, revisar, corregir y borrar cierta información personal al iniciar sesión en su cuenta.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <h4 style={{ textAlign: 'justify' }}>
                                <span style={{ fontSize: '20.0pt', lineHeight: '107%' }}>
                                    8. TRANSFERENCIAS INTERNACIONALES DE SU INFORMACIÓN
                                </span>
                            </h4>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Las empresas creadoras de ENDOFORCE están ubicadas en Colombia y la información personal que recopilamos sobre
                                    usted se almacenará principalmente en servidores en los Estados Unidos. Esto implicará una transferencia de datos
                                    fuera de su país o región, incluido el Espacio Económico Euripeo (EEE) y el Reino Unido.
                                </span>
                            </p>
                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>


                            <h4 style={{ textAlign: 'justify' }}>
                                <span style={{ fontSize: '20.0pt', lineHeight: '107%' }}>
                                    9. INFORMACIÓN SOBRE LOS NIÑOS
                                </span>
                            </h4>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Se entiende que ENDOFORCE es un videojuego para adaptar hábitos saludables en especial en niños y niñas, más sin embargo aclaramos que
                                    son los padres o tutores los responsables del registro, descarga e instalación de ENDOFORCE y el usuario registrado.
                                    En ningún momento se solicita información adicional a la mencionada en puntos anteriores a esta política.
                                    Los menores pueden usar ENDOFORCE, visualizar el contenido y disfrutar de muchas funcionalidades sin que se recopile o solicite
                                    información en el proceso; ademas, solicitaremos el consentimiento de alguno de los padres cuando así lo exija la ley.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    No recopilamos, utilizamos ni compartimos información personal de niños menores de 13 años deliberadamente sin el consentimiento comprobable de
                                    los padres o según lo permita la ley. Para efectos de estadísticas y operatividad de ENDOFORCE recopilamos la siguiente información del jugador
                                    sea niño o niña menor de 13 años o el registro que el padre o tutor considere:
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                   Número de días usando ENDOFORCE
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Puntajes mínimos, máximos y promedios por juego de ENDOFORCE
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Tiempos de juego relativos a hora de apertura, última sesión, frecuencia de apertura
                                </span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Información del dispositivo (Sistema operativo, conexión a internet activa)
                                </span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Compras en aplicación y renovación de subscripciones
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Para tener más claridad de la información a recopilar, el padre o tutor es el responsable de crear la cuenta
                                    del jugador con la información que considere mínima para operar ENDOFORCE, siendo un ejemplo el nombre de usuario
                                    como un álias, sin necesidad de ser el nombre real del menor.
                                </span>
                            </p>

                           {/* <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Por último si como padre o tutor desea mantener los datos del jugador de forma totalmente anónima sin que se guarde algún
                                    dato de información personal, puede acceder al menú, en el panel de "Mi perfil" puede desmarcar la casilla de concentimiento
                                    y luego toque en "Aceptar"
                                </span>
                            </p>*/}

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>


                            <h4 style={{ textAlign: 'justify' }}>
                                <span style={{ fontSize: '20.0pt', lineHeight: '107%' }}>
                                    10. MARCO LEGAL COLOMBIANO
                                </span>
                            </h4>
                            
                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    La presente Política de Protección de datos personales tiene como objeto poner
                                    en conocimiento de los usuarios y/o suscriptores del servicio de videojuego para
                                    dispositivos móviles “ENDOFORCE”, -el cual tiene como objeto incentivar hábitos
                                    saludables para niños, niñas y adolescentes dentro del territorio colombiano,
                                    de la mano de endocrinólogos pediatras de ENDOCIENCIA SAS-, la actividad desarrollada
                                    por el Responsable de su tratamiento, en virtud de los datos recolectados -en
                                    especial los de naturaleza sensible (médica/salud)-, al momento de la prestación
                                    del servicio antes mencionado.

                                    En consecuencia, toda la información recibida y recolectada por ENDOCIENCIA SAS en virtud de la prestación del servicio de videojuego para dispositivos móviles “ENDOFORCE” se rige por esta Política.
                                    El presente documento se desarrolla en cumplimiento de la Ley 1581 de 2012 y sus decretos reglamentarios.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    En consecuencia, toda la información recibida y recolectada por ENDOCIENCIA SAS
                                    en virtud de la prestación del servicio de videojuego para dispositivos móviles
                                    “ENDOFORCE” se rige por esta Política.
                                    El presente documento se desarrolla en cumplimiento de la Ley 1581 de 2012 y sus decretos reglamentarios.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    El presente documento se desarrolla en cumplimiento de la Ley 1581 de 2012
                                    y sus decretos reglamentarios.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                          
                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <h4 style={{ textAlign: 'justify' }}>
                                <span style={{ fontSize: '25.0pt', lineHeight: '107%' }}>
                                    11. FORMAS Y MECANIZMOS PARA OBTENER LA AUTORIZACIÓN DE PADRES Y/O TUTORES
                                </span>
                            </h4>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    La autorización podrá constar en un documento físico, documento
                                    electrónico, soporte de registro electrónico y/o digital, o en
                                    cualquier otro formato que permita garantizar su posterior consulta.
                                    La mencionada autorización, podrá ser obtenida por alguno, y
                                    sin excluirse otros de los siguientes medios:
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    • El registro de usuarios mediante ENDOFORCE. En la cual
                                    se solicitará  leer las políticas de privacidad para padres y/o tutores y usuarios en
                                    general para usar los servicios de la aplicación.
                                </span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                        
                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '15.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    Vigencia de la autorización
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    La vigencia de la autorización para el tratamiento de
                                    datos personales, -en especial los de naturaleza sensible-,
                                    relacionados con el estado de salud de los menores y/o
                                    usuarios/suscriptores del servicio de ENDOFORCE, será aquella necesaria
                                    para el cumplimiento del objeto de dicho servicio.

                                    Será mientras su actividad en la aplicación (registro y uso)
                                    sea utilizado.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>
                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>
                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>
                        
                            <h4 style={{ textAlign: 'justify' }}>
                                <span style={{ fontSize: '25.0pt', lineHeight: '107%' }}>
                                    12. PROCEDIMIENTO PARA GARANTIZAR EL DERECHO A PRESENTAR CONSULTAS Y/O RECLAMOS
                                </span>
                            </h4>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Los titulares de la información, -por intermedio del padre o tutor-, podrán ejercer sus derechos en cualquier momento y de manera gratuita,
                                    previa acreditación de su identidad y la de su hijo/a: endociencia.sas@gmail.com

                                    Es necesario que para la debida atención de solicitud relacione los siguientes datos:
.
                                </span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    <b>1. Nombres y apellidos completos </b> <br></br>
                                    <b>4. Telefono fijo y/o celular </b> <br></br>
                                    <b>5. Correo electrónico registrado en ENDOFORCE </b> <br></br>
                                    <b>6. Ciudad </b> <br></br>
                                    <b>7. Asunto </b> <br></br>
                                </span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>
                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <h4 style={{ textAlign: 'justify' }}>
                                <span style={{ fontSize: '25.0pt', lineHeight: '107%' }}>
                                    13. TÉRMINOS PARA LA ATENCIÓN DE CONSULTAS Y/O RECLAMOS
                                </span>
                            </h4>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    Respuesta a consultas
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    La consulta será atendida en un término máximo de diez (10) días hábiles contados a partir
                                    de la fecha de recibo de la misma. Cuando no fuere posible atender la consulta dentro de
                                    dicho término, se informará al interesado, expresando los motivos de la demora y señalando
                                    la fecha en que se atenderá su consulta, la cual en ningún caso podrá superar los cinco
                                    (5) días hábiles siguientes al vencimiento del primer término.
                                </span>
                            </p>

                            
                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    Respuesta a reclamos
                                </span>
                            </p>

                          

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Si el reclamo resulta incompleto, se requerirá al interesado dentro de los cinco
                                    (5) días siguientes a la recepción del reclamo para que subsane las fallas.
                                    Transcurridos dos (2) meses desde la fecha del requerimiento, sin que el solicitante
                                    presente la información requerida, se entenderá que ha desistido del reclamo.
                                </span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    b)	En caso de que quien reciba el reclamo no sea competente para resolverlo, se dará
                                    traslado a quien corresponda en un término máximo de dos (2) días hábiles e informará
                                    de la situación al interesado
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    c)	Una vez recibido el reclamo completo, se incluirá en la base de datos una leyenda que
                                    diga "reclamo en trámite" y el motivo del mismo, en un término no mayor a dos (2)
                                    días hábiles. Dicha leyenda deberá mantenerse hasta que el reclamo sea decidido.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    d)	 El término máximo para atender el reclamo será de quince (15) días hábiles contados a partir
                                    del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro
                                    de dicho término, se informará al interesado los motivos de la demora y la fecha en que se
                                    atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes
                                    al vencimiento del primer término.
                                </span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>
                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <h4 style={{ textAlign: 'justify' }}>
                                <span style={{ fontSize: '25.0pt', lineHeight: '107%' }}>
                                    14. CAMBIOS EN LA POLÍTICA DE PRIVACIDAD Y OBLIGACIÓN DEL USUARIO DE INFORMARNOS DE LOS CAMBIOS
                                </span>
                            </h4>



                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Podemos modificar esta Política de privacidad en cualquier momento cambiando su fecha de última actualización.
                                    Si hiciéramos cualquier cambio importante relativo a la forma en la que recopilamos, utilizamos o compartimos su
                                    información personal, explicaríamos dichos cambios y, si la legislación así lo exigiese, le pediríamos su consentimiento
                                    al respecto. Es importante que la información personal que conservamos sobre usted sea precisa y esté actualizada.
                                    Le rogamos que nos informe de cualquier cambio en su información personal mientras dure su relación con nosotros. Para ello,
                                    puede actializar la información de su perfil, o bien ponerse en contacto con nosotros a través de cualquiera de las direcciones
                                    indicadas al principio de esta Política de Privacidad.
                                </span>
                            </p>
                           
         

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '13.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                Si desea revisar o eliminar su información o la de su hijo o hija, solicitar información sobre nuestras
                                aplicaciones o nuestros proveedores y proveedores de servicios, contáctenos en facturacion@endoforce.co
                                </span>
                            </p>

                           

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    ENDOCIENCIA SAS
                                </span>
                            </p>

                           

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    NIT No. 901.133.184-6
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Sitio web con canales de soporte 
                                    <a href="https://www.endociencia.com"> www.endociencia.com</a>
                                </span>
                            </p>

                            
                        </div>
                    </div>
                </div>
                <style jsx>{`
          @font-face {
            font-family: 'Cambria Math';
            panose-1: 2 4 5 3 5 4 6 3 2 4;
          }

          @font-face {
            font-family: Calibri;
            panose-1: 2 15 5 2 2 2 4 3 2 4;
          }

          @font-face {
            font-family: 'Arial Nova Light';
            panose-1: 2 11 3 4 2 2 2 2 2 4;
          }

          @font-face {
            font-family: Aparajita;
            panose-1: 2 2 6 3 5 4 5 2 3 4;
          }

          /* Style Definitions */
          p.MsoNormal,
          li.MsoNormal,
          div.MsoNormal {
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 8pt;
            margin-left: 0cm;
            line-height: 107%;
            font-size: 11pt;
            font-family: 'Calibri', sans-serif;
          }

          h1 {
            mso-style-link: 'Heading 1 Char';
            margin-top: 12pt;
            margin-right: 0cm;
            margin-bottom: 0cm;
            margin-left: 0cm;
            line-height: 107%;
            page-break-after: avoid;
            font-size: 16pt;
            font-family: 'Arial Nova Light', sans-serif;
          }

          span.Heading1Char {
            mso-style-name: 'Heading 1 Char';
            mso-style-link: 'Heading 1';
            font-family: 'Arial Nova Light', sans-serif;
            font-weight: bold;
          }

          .MsoChpDefault {
            font-size: 11pt;
            font-family: 'Calibri', sans-serif;
          }

          .MsoPapDefault {
            margin-bottom: 8pt;
            line-height: 107%;
          }
        `}</style>
            </>
        )
    }
}
