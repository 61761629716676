import Endomedic from './Pages/EndoMedic'
import Faq from './Pages/Faq'
import { Home } from './Pages/Home'
import { PrivacyPolicy } from './Pages/PrivacyPolicy'
import Pay from './Pages/Payment/Pay'
import Response from './Pages/Payment/Response'
import { TermsOfService } from './Pages/TermsOfService'

const Navigation = {
  Routes: {
    Home: {
      Path: '/',
      Component: Home
    },
    Faq: {
      Path: '/Faq',
      Component: Faq
    },
    PrivacyPolicy: {
      Path: '/PrivacyPolicy',
      Component: PrivacyPolicy
        },
    TermsOfService: {
      Path: '/TermsOfService',
      Component: TermsOfService
    },
    Edomedict: {
      Path: '/Endomedic',
      Component: Endomedic
    },
    Payment: {
      Path: '/Payment/:planId',
      Component: Pay
    },
    Response: {
      Path: '/Response/:ref_payco',
      Component: Response
    }
  }
}

export default Navigation
