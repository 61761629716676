import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, useLocation } from 'react-router-dom';
import App from './App';

import registerServiceWorker from './registerServiceWorker';
import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const loader = () => {
  return (
    <>
      <div id="loader-container">
        <p id="loadingText">Ingresando</p>
      </div>
    </>
  );
};

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <ScrollToTop />
    <ClearBrowserCacheBoundary auto fallback={'Ingresando'} duration={60000}>
      <App />
    </ClearBrowserCacheBoundary>
  </BrowserRouter>,
  rootElement
);

registerServiceWorker();
