import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Guid } from 'js-guid';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { TermsOfService } from '../TermsOfService';

function GetConfiguration(type, host) {
  switch (parseInt(type)) {
    case 2:
      return {
        name: 'PLAN (12 MESES)',
        invoice: new Date().getTime().toString(16) + ':' + Guid.newGuid(),
        amount: 18000 * 12,
        description: 'PLAN (12 MESES)',
        currency: 'cop',
        tax_base: '0',
        tax: '0',
        country: 'co',
        lang: 'es',
        confirmation:
          process.env.REACT_APP_PAYCO_ENDOFORCE_CONFIRMATION +
          '/Payco/GetConfirmation',
        response: host + '/Response',
        external: 'true',
        extra1: '',
      };

    default:
      return {
        name: 'PLAN (6 MESES)',
        invoice: new Date().getTime().toString(16) + ':' + Guid.newGuid(),
        amount: 25000 * 6,
        description: 'PLAN (6 MESES)',
        currency: 'cop',
        tax_base: '0',
        tax: '0',
        country: 'co',
        lang: 'es',
        confirmation:
          process.env.REACT_APP_PAYCO_ENDOFORCE_CONFIRMATION +
          '/Payco/GetConfirmation',
        response: host + '/Response',
        external: 'true',
        extra1: '',
      };
  }
}

const Select = React.forwardRef(({ onChange, onBlur, name, label }, ref) => (
  <>
    <label>{label}</label>
    <select
      className="custom-select"
      name={name}
      ref={ref}
      onChange={onChange}
      onBlur={onBlur}
    >
      <option value="CC">CÉDULA DE CIUDADANÍA</option>
      <option value="CE">CÉDULA DE EXTRANJERÍA</option>
      <option value="DNI">DOCUMENTO NACIONAL DE IDENTIFICACIÓN</option>
    </select>
  </>
));

const Pay = () => {
  const { planId } = useParams();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const [isSending, setIsSending] = useState(false);
  const [isCouponValid, setIsCouponValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isCouponInValidation, setIsCouponInValidation] = useState(false);
  const watchCouponCode = watch('couponCode', '');
  const watchAllFields = watch();
  // eslint-disable-next-line no-unused-expressions
  const [configurationData, setConfigurationData] = useState(
    GetConfiguration(planId, window.location.origin)
  );
  const formatter = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  var dataUser = {
    email_billing: '',
    name_billing: '',
    type_doc_billing: '',
    mobilephone_billing: '',
    number_doc_billing: '',
  };

  const onValidateCoupon = async () => {
    setIsCouponInValidation(true);

    try {
      const response = await axios.get('/Coupon/' + watchCouponCode, {
        baseURL: process.env.REACT_APP_API_ENDOFORCE,
      });

      let coupon = response.data;
      let conf = GetConfiguration(planId, window.location.origin);
      if (coupon) {
        conf.amount -= conf.amount * (coupon.discount / 100);
      }
      setConfigurationData(conf);
      if (coupon) {
        setIsCouponValid(!true);
      } else {
        setValue('couponCode', '', { shouldValidate: false });
        setIsCouponValid(!false);
      }

      setIsCouponInValidation(false);
    } catch (err) {
      setIsCouponInValidation(false);
    }
  };

  const onSendForm = async (data) => {
    setIsSending(true);

    const MySwal = withReactContent(Swal);
    MySwal.fire({
      width: '90%',
      html: <TermsOfService top="5rem" />,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Rechazar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // eslint-disable-next-line no-undef
          let handler = ePayco.checkout.configure({
            key: 'c6493ff5a827dec0ec3761aa9e871af4',
            test: false,
          });

          let conf = GetConfiguration(planId, window.location.origin);
          let coupon = null;
          if(watchCouponCode){
            const response = await axios.get('/Coupon/' + watchCouponCode, {
              baseURL: process.env.REACT_APP_API_ENDOFORCE,
            });
            coupon = response.data;
          }

          if (coupon) {
            conf.amount -= conf.amount * (coupon.discount / 100);
            conf.description += ' Descuento ' + coupon.discount + '%';
            conf.extra1 = coupon.code.trim();
          }

          dataUser.email_billing = data.email;
          dataUser.name_billing = data.firstName + ' ' + data.lastName;
          dataUser.type_doc_billing = data.documentType;
          dataUser.number_doc_billing = data.documentNumber;
          dataUser.mobilephone_billing = data.phoneNumber;

          setIsCouponValid(!coupon);

          handler.open({
            ...conf,
            ...dataUser,
          });
        } catch (error) {
          setIsSending(false);
        }
      } else {
        setIsSending(false);
      }
    });
  };

  return (
    <>
      <div
        style={{
          marginTop: '15rem',
          marginBottom: '15rem',
        }}
        className="auto-container"
      >
        <form onSubmit={handleSubmit(onSendForm)} autoComplete={'on'}>
          <h2>Subscripcion {configurationData.description}</h2>

          <p>
           Estas adquiriendo una suscripción anticipada del
            videojuego.
          </p>
          <h4>Recuerda ingresar tus datos como padre y/o acudiente</h4>
          <p>
           al finalizar la transacción te enviaremos al correo registrado tu
           código de acceso para ingresar al videojuego una vez hagamos oficial
           su lanzamiento en Noviembre de 2021.
          </p>
          <div className="row clearfix">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="first">Nombre</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  {...register('firstName', { required: true })}
                />
                {errors.firstName && (
                  <small className="text-danger">El nombre es requerido</small>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="last">Apellidos</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  {...register('lastName', { required: true })}
                />
                {errors.lastName && (
                  <small className="text-danger">
                    El apellido es requerido
                  </small>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <Select
                  label="Tipo de documento"
                  {...register('documentType')}
                />
                {errors.documentType && (
                  <small className="text-danger">
                    Tipo de documento requerido
                  </small>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>Documento</label>
                <input
                  type="tel"
                  className="form-control"
                  placeholder=""
                  {...register('documentNumber', { required: true })}
                />
                {errors.documentNumber && (
                  <small className="text-danger">
                    Numero de documento requerido
                  </small>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Correo electrónico</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Correo electrónico"
                  {...register('email', { required: true })}
                />
                {errors.email && (
                  <small className="text-danger">Correo requerido</small>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>Correo electrónico</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Correo electrónico"
                  {...register('emailCopy', { required: true })}
                  onBlur={() => {
                    setIsEmailValid(
                      watchAllFields.emailCopy === watchAllFields.email
                    );
                  }}
                />
                {!isEmailValid && (
                  <small className="text-danger">
                    Los correos no son iguales
                  </small>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>Numero de Teléfono</label>
                <input
                  type="tel"
                  className="form-control"
                  {...register('phoneNumber', { required: true })}
                />
                {errors.phoneNumber && (
                  <small className="text-danger">Teléfono requerido</small>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <label>Cupón</label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  placeholder="Cupón"
                  className="form-control"
                  {...register('couponCode')}
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    disabled={isCouponInValidation}
                    className="btn btn-info"
                    onClick={onValidateCoupon}
                  >
                    {!isCouponInValidation && 'Aplicar'}
                    {isCouponInValidation && (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="sr-only">Cargando...</span>
                      </div>
                    )}
                  </button>
                </div>
              </div>
              {isCouponValid && (
                <small className="text-danger">Cupón invalido</small>
              )}
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>Total a pagar</label>
                <span className="form-control bg-gray-200">
                  {formatter.format(configurationData.amount)}
                </span>
              </div>
            </div>
          </div>

          <button
            disabled={isSending}
            type="submit"
            className="theme-btn btn-style-four mt-5"
          >
            Ir a comprar
          </button>
          {isSending && (
            <label className="text-center d-block">
              Redireccionando a ePayco...
            </label>
          )}
        </form>
      </div>
    </>
  );
};

export default Pay;
