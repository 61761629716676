import React, { Component } from 'react'
import { NavBar } from './Navbar'
import { Footer } from './Footer'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'animate.css/animate.css'
import 'jquery-ui/themes/base/all.css'
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'

export class Layout extends Component {
  static displayName = Layout.name

  render() {
    return (
      <>
        <NavBar />
        <main>{this.props.children}</main>
        <Footer />
        <div
          className='scroll-to-top scroll-to-target rounded-circle'
          data-target='html'
        >
          <span className='icon fa fa-angle-double-up'></span>
        </div>
      </>
    )
  }
}
