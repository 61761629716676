import React, { Component } from 'react'
import { Route } from 'react-router'
import { Layout } from './components/Layout'
import Endomedic from './Pages/EndoMedic'
import Faq from './Pages/Faq'
import Pay from './Pages/Payment/Pay'
import Navigation from './Navigation'
import './styles/globals.scss'
import 'jquery/dist/jquery'
import 'owl.carousel/dist/assets/owl.carousel.css'

export default class App extends Component {
  static displayName = App.name

  render() {
    return (
      <Layout>
        {Object.keys(Navigation.Routes).map((e, index) => (
          <Route
            key={'Route_' + index}
            exact
            path={Navigation.Routes[e].Path}
            component={Navigation.Routes[e].Component}
          />
        ))}
      </Layout>
    )
  }
}
