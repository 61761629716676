import React, { Component, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import $ from 'jquery'

const Navigation = () => {
  const location = useLocation()
  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset >= $('header').height()) {
        $('header').addClass('bg-white')
      } else {
        $('header').removeClass('bg-white')
      }
    }
  })
  return (
    <>
      <header className='main-header header-style-three fixed-header'>
        <div className='header-upper'>
          <div className='auto-container'>
            <div className='clearfix'>
              <div className='fa-pull-left logo-outer'>
                <div className='logo'>
                  <Link to='/'>
                    <img
                      src={require('../public/images/logo.png')}
                      alt=''
                      title=''
                    ></img>
                  </Link>
                </div>
              </div>

              <div className='fa-pull-right upper-right clearfix'>
                <div className='nav-outer clearfix'>
                  <nav className='main-menu navbar-expand-md'>
                    <div className='clearfix' id='navbarSupportedContent'>
                      <ul className='navigation clearfix d-flex justify-content-center'>
                        {!(location.pathname === '/') && (
                          <li>
                            <Link to='/'>
                              <mark>¡Endoforce!</mark>
                            </Link>
                          </li>
                        )}
                        {!(location.pathname === '/Endomedic') && (
                          <li>
                            <Link to='/Endomedic'>
                              <mark>Nuestra ciencia</mark>
                            </Link>
                          </li>
                        )}
                        {true && (
                          <li>
                            <Link to='/Faq'>
                              <mark>Preguntas</mark>
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export class NavBar extends Component {
  render() {
    return <Navigation />
  }
}
