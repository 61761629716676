import React, { Component } from 'react'
export class TermsOfService extends Component {
    render() {
      return (
            <>
                <div
                    className='container'
                    style={{ paddingTop:  this.props.top ?? '15rem', paddingBottom: '10rem' }}
                >
                    <div style={{ wordWrap: 'break-word' }}>
                        <div className='WordSection1'>
                            <h1 style={{ textAlign: 'justify' }}>
                                <span style={{ fontSize: '25.0pt', lineHeight: '107%' }}>
                                    TÉRMINOS Y CONDICIONES DEL SERVICIO POR SUSCRIPCIÓN DE “ENDOFORCE”
                                </span>
                            </h1>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <h2 style={{ textAlign: 'justify' }}>
                              <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                              </p>
                              <span style={{ fontSize: '15.0pt', lineHeight: '107%' }}>
                                Estos Términos y condiciones de uso se actualizaron el 03 de octubre de 2024.
                              </span>
                            </h2>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Antes de efectuar cualquier tipo de transacción electrónica,
                                    por favor lea detalladamente estas políticas de SUSCRIPCIÓN.
                                    Lo aquí expuesto será de acatamiento obligatorio para usuarios/suscriptores
                                    y ENDOCIENCIA SAS, al decidir la «SUSCRIPCIÓN» del videojuego, usted
                                    declara conocer y aceptar las condiciones y limitaciones establecidas en
                                    este documento:
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    &nbsp;
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    1. DEFINICIONES:
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '12.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    Sitio:
                                </span>
                                <span>
                                    Es el sitio web de ENDOFORCE cuyo link de acceso es www.endorce.co
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '12.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                   Endoforce:
                                </span>
                                <span>
                                    Es un producto de suscripción digital que brinda a los clientes acceso
                                    a una única aplicación para dispositivos móviles y tablets IOS y Android, la cual incentiva hábitos
                                    saludables para niños, niñas y adolescentes de la mano de endocrinólogos pediatras
                                    de ENDOCIENCIA SAS.
                                </span>
                                <span>
                                    Cuenta con servicos de suscripción disponibles de forma trimestral o anual de renovación automática.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '12.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    Proveedor:
                                </span>
                                <span>
                                    ENDOCIENCIA SAS, identificado con NIT No. 901.133.184-6,
                                    es el único proveedor del servicio de videojuego para dispositivos
                                    móviles “ENDOFORCE” ofertado y comercializado en las tiendas de aplicaciones
                                    AppStore (Apple) y PlayStore (Android).
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '12.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    Derecho de retracto:
                                </span>
                                <span>
                                    Facultad de arrepentimiento del usuario/suscriptor,
                                    en razón a su la libertad de cambiar la motivación
                                    de compra (SUSCRIPCIÓN).
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '12.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    Reversión del pago:
                                </span>
                                <span>
                                    Es un mecanismo legal que protege los derechos de los consumidores
                                    o clientes que compran productos o servicios a través de comercio
                                    electrónico y canales no presenciales, permitiéndoles solicitar
                                    el reembolso de lo pagado, a cambio de la devolución del producto
                                    comprado (cuando aplique), siempre que se presente 1 de los 5 casos
                                    que mencionamos más adelante y se cumplan algunos requisitos.
                                </span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                 1. APLICACIÓN:
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                  1.1 Si no acepta y cumple con estos términos de uso, no debe acceder ni utilizar este
                                  Sitio (www.endoforce.co) o Endoforce. Estos términos de uso se aplican a este sitio web y Endoforce, programas
                                  de suscripción digital y que forman parte de los servicios que ofrece el Proveedor.
                                </span>
                             </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>
                                  1.2 Estos Términos rigen la relación entre usted y el Proveedor con respecto a su uso del sitio web y Endoforce.
                                  Nos reservamos el derecho en cualquier momento de cambiar: (i) los términos y condiciones de estos Términos de uso;
                                  (ii) el sitio web o Endoforce, incluyendo terminar, eliminar, complementar, modificar, agregar o discontinuar
                                  cualquier contenido o característica o datos o servicio en o disponible a través del sitio web o Endoforce las
                                  horas en que están disponibles; (iii) cualquier tarifa o cargo, si corresponde, relacionado con el uso del sitio web o
                                  Endoforce; y (iv) el equipo, hardware o software necesario para usar y acceder al sitio web o Endoforce. En caso de
                                  cambios, suspensiones o terminaciones del sitio web o Endoforce, le notificaremos dicho cambio, suspensión o terminación con
                                  anticipación. No estaremos obligados a proporcionar reembolsos, beneficios u otra compensación en relación con dicho cambio,
                                  suspensión o terminación del sitio web o Endoforce, o partes de los mismos.
                              </span>
                             </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>
                                  1.3 Cualquier cambio que realicemos a estos Términos de uso entrará en vigor inmediatamente después de la notificación,
                                  que podemos proporcionar por cualquier medio, incluido, entre otros, la publicación en el sitio web. Su uso continuado del
                                  sitio web o Endoforce después de dicho aviso se considerará aceptación de dichos cambios. Asegúrese de regresar a este sitio web
                                  periódicamente para asegurarse de estar familiarizado con la versión más actualizada de estos Términos de uso.
                              </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span
                                style={{
                                  fontSize: '12.0pt',
                                  lineHeight: '107%',
                                  fontFamily: '"Arial Nova Light",sans-serif'
                                }}
                              >
                              SI USTED ES PADRE O TUTOR Y DA SU CONSENTIMIENTO AL USO O REGISTRO DE SU HIJO EN ESTE SITIO O LOS SERVICIOS,
                              AL BRINDAR DICHO CONSENTIMIENTO, ACEPTA ESTAR SUJETO A ESTOS TÉRMINOS DE USO CON RESPECTO AL USO DE LOS SITIOS POR
                              PARTE DE SU HIJO. Y LOS SERVICIOS.
                              </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    2.  CONDICIONES MÍNIMAS PARA ACCEDER AL SERVICIO Y OTRAS DISPOSICIONES DE USO:
                                </span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '12.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    2.1 Información de las condiciones mínimas para acceder al servicio de videojuego para dispositivos móviles “ENDOFORCE”:
                                </span>
                                <span>
                                    •	Para instalar la aplicación “ENFORCE” se requiere un espacio mínimo de 5GB de memoria en su dispositivo móvil.</span>
                                <span> •	Para la conexión en dispositivos Android (smartphone y tablet) se debe descargar la aplicación gratuita que ofrece Google Play Store. Para una óptima utilización de los sistemas operativos Android, se advierte como requisito mínimo que la versión no puede ser anterior a 10.</span>

                                <span> •	Para la conexión en dispositivos iOS (smartphone y tablet) se debe descargar la aplicación gratuita de Apple Store. Para una óptima utilización de los sistemas
                                operativos iOS, se advierte como requisito mínimo que la versión no puede ser anterior a 12.</span>

                             </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    3. PRIVACIDAD:
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                  Nuestro Política de privacidad (https://endoforce.co/PrivacyPolicy) es una parte integrada de estos Términos.
                                </span>
                                <span>
                                  Para obtener información sobre el procesamiento de información personal recopilada a través del sitio web y Endoforce, consulte nuestro Política de privacidad.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    4. PLANES DEL SERVICIO:
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Cualquier persona natural, mayor de 18 años puede adquirir Endoforce a través de
                                    cualquiera de los siguientes planes:
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '12.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                PLAN TRIMESTRAL DE RENOVACIÓN AUTOMÁTICA:
                                </span>
                                <span>
                                    • Acceso a todos los minijuegos que incluye la App.
                                    • Intensidad progresiva del juego
                                    • Retos diarios en los minijuegos
                                    • Recordatorios diarios de hábitos saludables.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '12.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    PLAN ANUAL DE RENOVACIÓN AUTOMÁTICA:
                                </span>
                                <span>
                                    • Acceso a todos los minijuegos que incluye la App
                                    • Intensidad progresiva del juego
                                    • Retos diarios en los minijuegos
                                    • Recordatorios diarios de hábitos saludables
                                    • Plataforma de resultados del jugador en línea
                                    • 30% de descuento en la primera teleconsulta por el equipo médico de Endociencia
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>&nbsp;</span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    5. DERECHO DE RETRACTO:
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Al ser la facultad de arrepentimiento que posee el usuario/suscriptor,
                                    este podrá ejercer tal derecho, de conformidad con en el artículo 47
                                    de la Ley 1480 de 2011 (Estatuto del Consumidor), siempre y cuando se
                                    cumplan los siguientes requisitos: a) que dicho derecho sea ejercido
                                    en el plazo de cinco (5) días hábiles a partir de la fecha en que
                                    pagó el Servicio, mediante el envío de una comunicación a través del
                                    correo electrónico facturacion@endoforce.co manifestando que ejerce
                                    el derecho de retracto; y , b) Que dentro de ese plazo de cinco (5)
                                    días hábiles mencionado, el Suscriptor no haya utilizado el Servicio.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '12.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    Si alguno de los dos requisitos no se cumple, no habrá lugar a la devolución del dinero.
                                </span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    6. REVERSIÓN DEL PAGO:
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    El usuario/suscriptor podrá solicitar la reversión del pago según
                                    corresponda el caso, a los cinco (5) días siguientes dentro de los
                                    cuales concurra alguna de las siguientes circunstancias: (i) cuando
                                    el Suscriptor sea objeto de un fraude; (ii) cuando el pago del
                                    Servicio corresponda a una operación no solicitada; (iii) cuando
                                    el Servicio no sea recibido; (iv) cuando el Servicio no corresponda
                                    a lo solicitad; es decir que no cumpla con las características que
                                    le fueron informadas por el proveedor, y (v) cuando el Servicio sea
                                    defectuoso.

                                    Para que proceda la reversión del pago, dentro los cinco (5) días
                                    hábiles siguientes a la fecha en que haya ocurrido cualquiera de las
                                    siguientes situaciones, el usuario/suscriptor deberá presentar queja
                                    ante ENDOCIENCIA SAS al correo: facturacion@endoforce.co  indicando:
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span> • Nombre e identificación.</span>
                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>

                                <span> • Asunto: Solicitud de reversión del pago y causal invocada.</span>
                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>

                                <span> • Identificación del plan adquirido y fecha de adquisición.</span>
                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>

                                <span> • Medio de pago (identificación de la cuenta bancaria, tarjeta de crédito o instrumento de
                                    pago al que fue cargada la operación).</span>
                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>

                                <span> • Valor por el que se solicita la reversión.</span>
                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>

                                <span> • Hechos que sustentan la solicitud y las pruebas que soportan a la misma.</span>
                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>


                                <span> • Causal invocada para la reversión del pago</span>
                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>

                                <span> • Datos de contacto (dirección, teléfono, celular, correo electrónico).</span>
                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>
                              
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span
                                style={{
                                  fontSize: '14.0pt',
                                  lineHeight: '107%',
                                  fontFamily: '"Arial Nova Light",sans-serif'
                                }}
                              >
                                7. CUENTAS
                              </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>
                                Suscribirse a cualquier suscripción o servicio digital del proveedor requerirá que cree una cuenta.
                            </span>
                            <span>
                              Podemos suspender o cancelar su capacidad de utilizar cualquiera de nuestras suscripciones
                              o servicios digitales en caso de incumplimiento de estos Términos o cualquier término especial
                              relacionado con Endoforce en particular. </span>
                            <span>
                              Al crear una cuenta, usted acepta recibir correos electrónicos promocionando cualquier oferta
                              especial, incluidas ofertas de terceros. Puede optar por no recibir promociones especiales o
                              correspondencia de marketing nuestra cancelando la suscripción como se indica en la correspondencia
                              por correo electrónico correspondiente.
                            </span>
                            <span>
                              Puede comunicarse con el soporte de la cuenta enviando un correo electrónico a cualquiera de las
                              siguientes direcciones de correo electrónico: facturacion@endoforce.co
                            </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>&nbsp;</span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span
                                style={{
                                  fontSize: '14.0pt',
                                  lineHeight: '107%',
                                  fontFamily: '"Arial Nova Light",sans-serif'
                                }}
                              >
                                8. INFORMACIÓN RECOPILADA
                              </span>
                            </p>

                               <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span
                                style={{
                                  fontSize: '15.0pt',
                                  lineHeight: '107%',
                                  fontFamily: '"Arial Nova Light",sans-serif'
                                }}
                              >
                                Rentención de datos
                              </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                Nuestros plazos de conservación de datos personales se basan en necesidades
                                comerciales y requisitos legales. Conservamos datos personales durante el
                                tiempo que sea necesario para los fines de procesamiento para los cuales se
                                recopilaron los datos y cualquier otro propósito relacionado permitido.
                                Por ejemplo, podemos conservar ciertos detalles y correspondencia de la
                                transacción hasta que haya expirado el plazo para los reclamos que surjan
                                de la transacción. Para obtener más información sobre cómo procesamos la
                                información personal recopilada a través de nuestros sitio web y Endoforce,
                                consulte nuestro Política de privacidad.
                                </span>
                            </p>

                               <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span
                                style={{
                                  fontSize: '15.0pt',
                                  lineHeight: '107%',
                                  fontFamily: '"Arial Nova Light",sans-serif'
                                }}
                              >
                                Creación de la cuenta
                              </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                Para crear una cuenta en Endoforce, requerimos lo siguiente de usted:
                                </span>
                            </p>

                            <span> • Dirección de correo electrónico</span>
                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>

                                <span> • Contraseña.</span>
                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>

                                 <span> • Nombre de usuario</span>
                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>

                                <span> • Nombre completo del usuario/cliente.</span>
                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>

                                 <span> • Fecha de nacimiento</span>
                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>

                                <span> • Sexo (Opcional).</span>
                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>

                                <span> • Rango de fecha en la que usualmente va a dormir.</span>
                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>

                                <span> • Acuerdo en nuestra política de privacidad.</span>
                                <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span
                                style={{
                                  fontSize: '14.0pt',
                                  lineHeight: '107%',
                                  fontFamily: '"Arial Nova Light",sans-serif'
                                }}
                              >
                                9. COMPRAS
                              </span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                Requerimos que tenga una cuenta existente para comprar cualquier suscripción. Cuando compra una suscripción, es posible
                                que le solicitemos la siguiente información adicional:
                                </span>
                            </p>

                            <span> • Acuerdo en nuestra política de privacidad.</span>
                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>&nbsp;</span>
                            </p>

                            <span> • Acuerdo en nuestros términos del servicio.</span>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>&nbsp;</span>
                            </p>

                            
                              <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                Endoforce usa directamente las pasarelas de pago de las tiendas de aplicaciones móviles (AppStore y PlayStore)
                                siendo estas las responsables en la gestión de sus datos de pago como (nombre, número, fecha de vencimiento y código CVC de la facturación de crédito)
                                Términos y Condiciones de Venta)
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>&nbsp;</span>
                            </p>

                              <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span
                                style={{
                                  fontSize: '14.0pt',
                                  lineHeight: '107%',
                                  fontFamily: '"Arial Nova Light",sans-serif'
                                }}
                              >
                                10. TÉRMINOS Y CONDICIONES DE  VENTA
                              </span>
                            </p>

                               <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                                </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span
                                style={{
                                  fontSize: '15.0pt',
                                  lineHeight: '107%',
                                  fontFamily: '"Arial Nova Light",sans-serif'
                                }}
                              >
                                Facturación y pagos
                              </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                 El precio de Endoforce se paga en su totalidad antes de su entrega y se le cobrará automáticamente cada periodo
                                 por su suscripción continua. Si se ha comprometido a un período de suscripción que dura, por ejemplo, un trimestre,
                                 se le cobrará automáticamente el costo total de esa suscripción el día de su suscripción.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                 A menos que cancele su suscripción o membresía antes del final de su período de suscripción actual, al final de ese
                                 período su suscripción se renovará automáticamente por un período de suscripción adicional de la misma cantidad de
                                 tiempo. Esto se aplica tanto a los programas de suscripción trimestral como anual.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                Si su método de pago llega a su fecha de vencimiento y no edita la información de su tarjeta de crédito/débito y tiene
                                una suscripción continua, nos autoriza a continuar facturando la tarjeta de crédito/débito registrada. Esto incluye
                                extender la fecha de vencimiento hasta que usted o la compañía de la tarjeta de crédito/débito nos notifiquen que la
                                cuenta ya no es válida. Le recomendamos que actualice constantemente su información de pago o cancele su membresía si
                                desea suspender su compra trimestral o anual.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                Utilizamos servicios de facturación de suscripción y pago de terceros en lugar de procesar directamente la información
                                de su suscripción y tarjeta de crédito/débito. Al enviar la información de su tarjeta de crédito/débito, nos otorga el
                                derecho de almacenar y procesar su información con los servicios de pago de terceros.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                Usted reconoce que podemos cambiar el servicio de pago de terceros y trasladar su información a otros proveedores de
                                servicios que cifran su información utilizando tecnología de capa de socket segura (SSL) u otra tecnología de seguridad
                                comparable.
                                </span>
                            </p>

                             <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                Todos los asuntos financieros relacionados con su información están sujetos a las condiciones de los términos de servicio de los proveedores de servicios de pago externos’.
                                La versión actual de estos se puede encontrar en:
                                </span>
                            </p>

                             <span> • Apple Pay https://support.apple.com/en-us/HT203027.</span>

                             <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                             </p>

                             <span> • Google Pay https://policies.google.com/privacy.</span>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                    <span>&nbsp;</span>
                            </p>

                             <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span
                                style={{
                                  fontSize: '15.0pt',
                                  lineHeight: '107%',
                                  fontFamily: '"Arial Nova Light",sans-serif'
                                }}
                              >
                                Servicios, Productos y Precios
                              </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                 Todos los servicios y poductos digitales con sus descripciones y sus precios están sujetos a cambios.
                                 Nos reservamos el derecho de modificar, suspender o discontinuar la venta de cualquier Producto o Servicio,
                                 con o sin previo aviso.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                En el caso de que un Producto o Servicio aparezca a un precio incorrecto o con información incorrecta debido
                                a un error tipográfico o error en el precio, tenemos derecho, antes de la aceptación de su pedido, a rechazar
                                o cancelar dichos pedidos, ya sea o no. el pedido ha sido confirmado y/o su tarjeta de crédito/débito cargada.
                                Si su tarjeta de crédito/débito ya ha sido cargada por el pedido y cancelamos su pedido, emitiremos un reembolso
                                a su cuenta de tarjeta de crédito/débito por el monto del cargo.
                                </span>
                            </p>

                             <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span
                                style={{
                                  fontSize: '15.0pt',
                                  lineHeight: '107%',
                                  fontFamily: '"Arial Nova Light",sans-serif'
                                }}
                              >
                                Promociones: Sus reglas y limitaciones
                              </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                 Lea las reglas oficiales que acompañan a cada oferta, cupón, descuento o concurso especial que podamos ofrecer o realizar.
                                 No se pueden utilizar ofertas especiales, cupones o descuentos junto con otras ofertas a menos que se especifique lo
                                 contrario.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                   11. NUESTROS DERECHOS DE PROPIEDAD
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Todos los títulos, propiedad y derechos de propiedad intelectual sobre el sitio web y
                                    Endoforce son propiedad del Provedor. Usted reconoce y acepta que los
                                    servicios contienen información confidencial y de propiedad que está protegida por la propiedad
                                    intelectual aplicable y otras leyes. Salvo que el Proveedor lo autorice expresamente, usted acepta
                                    no modificar, alquilar, arrendar, prestar, vender, distribuir o crear trabajos derivados basados
                                    en los servicios, en su totalidad o en parte.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                   12. DIVISIBILIDAD
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                 Si alguna parte de estos Términos y condiciones se considera ilegal, nula o inaplicable, esa parte se
                                 considerará divisible y no afectará la validez y aplicabilidad de las disposiciones restantes.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>&nbsp;</span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    13. TERRITORIALIDAD DEL SERVICIO:
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Si el usuario/suscripto está domiciliado en Colombia los
                                    “Términos y Condiciones” se encuentran sujetos y regidos
                                    por las leyes vigentes en el territorio Colombiano en
                                    materia del Derecho del Consumo y demás disposiciones.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                              <span>&nbsp;</span>
                            </p>

                             <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span
                                    style={{
                                        fontSize: '14.0pt',
                                        lineHeight: '107%',
                                        fontFamily: '"Arial Nova Light",sans-serif'
                                    }}
                                >
                                    14. VARIOS:
                                </span>
                            </p>


                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    En cualquier momento, podemos modificar estos Términos de uso modificando, eliminando
                                    y/o agregando cualquier parte de los mismos.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Si realizamos una enmienda importante a estos Términos de uso, le notificaremos dicha
                                    enmienda enviándole un correo electrónico a la última dirección de correo electrónico
                                    que nos proporcionó y/o publicando un aviso de dicha enmienda en los Sitios cubiertos
                                    por estos Términos de uso o a través de la tienda de aplicaciones correspondiente o de
                                    la propia aplicación.
                                </span>
                            </p>

                             <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Disponibilidad: Si bien nos esforzamos por mantener el tiempo de inactividad al mínimo,
                                    no podemos prometer que este sitio web o Endoforce serán ininterrumpidos, seguros o
                                    estarán libres de errores. Nos reservamos el derecho de interrumpir/suspender este sitio web o Endoforce,
                                    o cualquier parte de los mismos, con o sin previo aviso por cualquier motivo
                                    y usted no tendrá derecho a ningún reembolso de tarifas por la interrupción de este sitio web o Endoforce.
                                </span>
                            </p>


                             <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Acceso a Internet: Usted es el único responsable de obtener y mantener todo Internet, hardware informático
                                    y otros equipos necesarios para acceder y utilizar este Sitio y los Servicios y será el único responsable
                                    de todos los cargos y tarifas relacionados con los mismos.
                                </span>
                            </p>

                             <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Nuestros Materiales: Este sitio web y Endoforce son propiedad de el Proveedor (Endociencia S.A.S.) y
                                    están operados por ella en
                                    conjunto con otros de conformidad con acuerdos contractuales. El Proveedor puede poner a su disposición
                                    cierto contenido, software u otros materiales electrónicos (incluidos todos los archivos, texto, URL,
                                      videos, audio e imágenes contenidos o generados por dichos materiales, y los datos que los acompañan)
                                      (colectivamente "Materiales de El Proveedor") desde este sitio web o Endoforce, de vez en cuando. Si descarga
                                      o utiliza los Materiales de El Proveedor, acepta que dichos materiales: (i) solo pueden usarse para su uso personal
                                      y no comercial, (ii) no pueden modificarse, usarse para crear un trabajo derivado, incorporarse a ningún
                                      otro trabajo. o explotado de otro modo sin el permiso previo por escrito de El Proveedor, y (iii) solo se
                                      utilizará de conformidad con los términos de licencia adicionales que acompañen a dichos materiales.
                                      El Proveedor no transfiere ni el título ni los derechos de propiedad intelectual de los Materiales de El Proveedor
                                      y conserva el título completo de los Materiales de El Proveedor, así como todos los derechos de propiedad
                                      intelectual contenidos en ellos. Usted acepta no vender, compartir, redistribuir ni reproducir los
                                      Materiales de El Proveedor. Además, acepta no descompilar, aplicar ingeniería inversa, desensamblar ni convertir
                                      de otro modo ningún Material de El Proveedor a una forma perceptible por el hombre. El Proveedor o sus licenciantes
                                      poseen todas las marcas comerciales y logotipos relacionados, y usted acepta no copiarlos ni utilizarlos
                                      de ninguna manera. Este Sitio (incluida su organización y presentación y los Materiales de El Proveedor) es
                                      propiedad de El Proveedor y sus licenciantes y puede estar protegido por leyes de propiedad intelectual,
                                      incluidas leyes relacionadas con derechos de autor, marcas comerciales, nombres comerciales, nombres
                                      de dominio de Internet y otros derechos similares.
                                </span>
                            </p>

                             <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Sus Materiales: Al publicar, descargar, mostrar, realizar, transmitir o distribuir de otro modo información
                                    u otro contenido ("Materiales de usuario") a este sitio web o en Endoforce por su parte o bajo su dirección,
                                    está otorgando a El Proveedor, sus afiliados, socios, funcionarios, directores, empleados, consultores, agentes
                                    y representantes una licencia para utilizar dichos Materiales de usuario en relación con la operación de este
                                    Sitio y los Servicios, incluidos, un derecho a copiar, distribuir, transmitir, mostrar públicamente, ejecutar
                                    públicamente, reproducir, editar, traducir y reformatear dichos Materiales de usuario. No recibirá compensación
                                    por ningún Material de usuario. Usted acepta que, a menos que indique lo contrario, El Proveedor puede publicar
                                    o divulgar su nombre de usuario en relación con sus Materiales de usuario. Al publicar Materiales de usuario
                                    en este sitio web o en Endoforce, usted garantiza y declara que posee los derechos de los Materiales de usuario
                                    o que está autorizado de otro modo a publicar, distribuir, mostrar, realizar, transmitir o distribuir de otro
                                    modo los Materiales de usuario y que dichos Materiales de usuario no no violar ninguna ley aplicable. Usted
                                    acepta que la carga de demostrar que cualquier Material de usuario no viola ninguna ley o derecho de terceros
                                    recae únicamente en usted. Usted es el único responsable de sus Materiales de usuario. Usted es el único
                                    responsable de realizar y conservar copias de seguridad de sus Materiales de usuario y de aplicar el nivel
                                    adecuado de derechos de acceso a los mismos. El Proveedor no será responsable de la eliminación o exactitud de
                                    ningún Material de usuario, la falta de almacenamiento, transmisión, cifrado (o seguridad de otro modo) o
                                    recepción de cualquier Material de usuario, o el almacenamiento, transmisión, cifrado (u otra seguridad) o
                                    recepción de cualquier otra comunicación que implique el uso de este sitio web o en Endoforce. Usted es el único
                                    responsable de obtener y mantener los consentimientos o permisos necesarios de cualquier tercero cuya
                                    información pueda recopilarse, registrarse, procesarse, almacenarse, usarse, divulgarse, transferirse,
                                    intercambiarse o manejarse de otro modo como resultado de, o como parte de, cualquier Material de usuario
                                    o cualquier comunicación que implique el uso de este sitio web o en Endoforce y acepta obtener y mantener
                                    todos dichos consentimientos o permisos durante la vigencia de este acuerdo.
                                </span>
                            </p>

                            <p className='MsoNormal' style={{ textAlign: 'justify' }}>
                                <span>
                                    Plazo y terminación. El Proveedor puede, de vez en cuando, pero de ninguna manera está obligado a hacerlo,
                                    permitirle acceder y utilizar este sitio web y Endoforce de acuerdo con estos Términos de uso y de la
                                    manera más particularmente establecida en este documento. Usted reconoce y acepta que el acceso a este
                                    Sitio y a los Servicios puede no estar disponible de vez en cuando, puede modificarse, revisarse,
                                    reemplazarse, suspenderse o cancelarse total o parcialmente en cualquier momento y sin previo aviso,
                                    y que El Proveedor no lo hará, en cualquier caso, ser responsable ante usted de cualquier manera en caso
                                    de que no pueda acceder a este Sitio y al Servicio en cualquier momento o de vez en cuando. El Proveedor
                                    puede, a su exclusivo criterio, en cualquier momento y de vez en cuando, sin previo aviso, suspender su
                                    derecho a utilizar este sitio web y Endoforce y/o rescindir estos Términos de uso o cualquiera de las
                                    licencias otorgadas en virtud del presente. Sin limitar lo anterior, estos Términos de uso y las licencias
                                    otorgadas en este documento terminarán automáticamente, sin previo aviso, si usted no cumple materialmente
                                    con estos Términos de uso o cualquier disposición de los mismos. Tras la terminación de estos Términos de uso,
                                    usted deberá cesar y desistir inmediatamente de todo uso de este sitio web y Endoforce. Sus obligaciones en
                                    virtud de estos Términos de uso sobrevivirán a la terminación de estos Términos de uso.
                                </span>
                            </p>

                           

                        </div>
                    </div>
                 </div>
        
                <style jsx>{`
          @font-face {
            font-family: 'Cambria Math';
            panose-1: 2 4 5 3 5 4 6 3 2 4;
          }

          @font-face {
            font-family: Calibri;
            panose-1: 2 15 5 2 2 2 4 3 2 4;
          }

          @font-face {
            font-family: 'Arial Nova Light';
            panose-1: 2 11 3 4 2 2 2 2 2 4;
          }

          @font-face {
            font-family: Aparajita;
            panose-1: 2 2 6 3 5 4 5 2 3 4;
          }

          /* Style Definitions */
          p.MsoNormal,
          li.MsoNormal,
          div.MsoNormal {
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 8pt;
            margin-left: 0cm;
            line-height: 107%;
            font-size: 11pt;
            font-family: 'Calibri', sans-serif;
          }

          h1 {
            mso-style-link: 'Heading 1 Char';
            margin-top: 12pt;
            margin-right: 0cm;
            margin-bottom: 0cm;
            margin-left: 0cm;
            line-height: 107%;
            page-break-after: avoid;
            font-size: 16pt;
            font-family: 'Arial Nova Light', sans-serif;
          }

          span.Heading1Char {
            mso-style-name: 'Heading 1 Char';
            mso-style-link: 'Heading 1';
            font-family: 'Arial Nova Light', sans-serif;
            font-weight: bold;
          }

          .MsoChpDefault {
            font-size: 11pt;
            font-family: 'Calibri', sans-serif;
          }

          .MsoPapDefault {
            margin-bottom: 8pt;
            line-height: 107%;
          }
        `}</style> 
        </>
        )
    }
}
