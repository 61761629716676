import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class Footer extends Component {
  render() {
    return (
      <>
        <footer className='main-footer'>
          <div className='footer-bottom'>
            <div className='auto-container'>
              <div className='logo'>
                <Link to='/'>
                  <img
                    src={require('../public/images/footer-logo.png')}
                    alt=''
                  />
                </Link>
              </div>
              <div className='social-nav'>
                <Link to='/PrivacyPolicy'>Política de privacidad</Link>|
                <Link to='/Faq'>Preguntas y respuestas</Link>|
                <Link to='/TermsOfService'>Términos y condiciones del servicio</Link>
              </div>
              <div className='social-nav'>
               <Link to='https://www.facebook.com/endociencia/'>
                  <span className='fab fa-facebook'></span>
                </Link>
               <Link to='#'>
                  <span className='fab fa-twitter'></span>
                </Link>
              </div>
            </div>
          </div>
        </footer>
      </>
    )
  }
}
