import React, { useState, Component } from 'react'
import Plan from '../components/Payment/Plan'
import { Link } from 'react-router-dom'

const AcceptTermsOfServiceAndConditions = ({
  checkedPreOrder,
  onAcceptTermsOfServiceAndConditions
}) => {
  return (
    <>
      <div className='form-check p-2 '>
         <Link to='/TermsOfService'> Ver Términos y condiciones del servicio. </Link>
      </div>
    </>
  )
}

function Payment() {
  const [allowPreOrder, setAllowPreOrder] = useState(false)
  const formatter = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  })

  const productItems = [
    {
      added: true,
      description: 'Acceso a todos los mini juegos'
    },
    {
      added: true,
      description: 'Intensidad progresiva del juego'
    },
    {
      added: true,
      description: 'Retos todos los días'
    },
    {
      added: true,
      description: 'Recordatorios diarios de hábitos saludables'
    },
    {
      added: false,
      description: 'Plataforma de resultados del jugador en línea '
    },
    {
      added: false,
      description: '30% de descuento en la primera teleconsulta por el equipo médico de Endociencia '
    }
  ]
  const productItems2 = [
    {
      added: true,
      description: 'Acceso a todos los mini juegos'
    },
    {
      added: true,
      description: 'Intensidad progresiva del juego'
    },
    {
      added: true,
      description: 'Retos todos los días'
    },
    {
      added: true,
      description: 'Recordatorios diarios de hábitos saludables'
    }
  ]

  return (
    <>
      <section id='PreOrder' className='pricing-section style-two'>
        <div className='auto-container'>
          <div className='sec-title centered'>
            <h2>
              <mark className='text-bg-fade box-yellow'>
                <span className='h3'>
                  Conoce nuestros planes de <b>suscripción</b>
                </span>
                <br />
                <span className='h4'> Cifras en pesos colombianos </span>
              </mark>
            </h2>
          </div>

          <div className='pricing-inner-container'>
            <div className='monthly-package'>
              <div className='row justify-content-center'>
                <div className='col-12 col-lg-8 text-center p-0 bg-white'>
                  <AcceptTermsOfServiceAndConditions
                    checkedPreOrder={allowPreOrder}
                    onAcceptTermsOfServiceAndConditions={setAllowPreOrder}
                  />
                </div>
              </div>
              <div className='row justify-content-center'>
                <Plan
                  title='PLAN ANUAL'
                  price={formatter.format(349900)}
                  description={
                    //formatter.format(30000 * 12) + ' '
                    'Renovación automática ANUAL'
                  }
                  items={productItems.map((e, index, arr) => {
                    let ret = {
                      added: !(index === arr.length),
                      description: e.description
                    }
                    return ret
                  })}
                  allowPreOrder={allowPreOrder}
                  type={1}
                />
                <Plan
                  title='PLAN TRIMESTRAL'
                  price={formatter.format(99900)}
                  description={
                    //formatter.format(33000 * 3) + ' facturados trimestralmente'
                    'Renovación automática TRIMESTRAL'
                  }
                  items={productItems2}
                  allowPreOrder={allowPreOrder}
                  type={2}
                />

                <div className='col-12 col-lg-8 text-center p-0 bg-white'>
                  <AcceptTermsOfServiceAndConditions
                    checkedPreOrder={allowPreOrder}
                    onAcceptTermsOfServiceAndConditions={setAllowPreOrder}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export class PreOrder extends Component {
  render() {
    return (
      <>
        <Payment />
      </>
    )
  }
}
