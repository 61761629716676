import React, { useState, useEffect, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const Response = () => {
  const [transaction, setTransaction] = useState({});
  const [ready, setReady] = useState(false);
  let { ref_payco } = useParams();

  useEffect(() => {
    axios
      .get('https://secure.epayco.co/validation/v1/reference/' + ref_payco)
      .then((resp) => {
        setTransaction(resp.data.data);
        setReady(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref_payco]);

  const formatter = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  return (
    <>
      <div
        style={{
          marginTop: '7rem',
          marginBottom: '15rem',
        }}
        className="auto-container"
      >
        <Suspense fallback={<p>Consultando estado de la transacción</p>}>
          {ready && (
            <>
              <section className="services-section-two">
                <div className="inner-box">
                  <div className="row clearfix">
                    <div className="content-column col-lg-6 col-md-12 col-sm-12">
                      <h2 className="text-light text-left">
                        <mark className="text-bg-fade box-purple">
                          ¡GRACIAS!
                        </mark>
                      </h2>

                      <br />

                      <h4>Respuesta {ref_payco}</h4>

                      <h5>{transaction.x_respuesta}</h5>
                      <p style={{ marginTop: '2rem' }}>
                        {transaction.x_business}
                        <br />
                        <b>{transaction.x_description}</b>
                        <br />
                        {formatter.format(transaction.x_amount)}
                        <br />
                        <b>Fecha de transacción: </b>
                        {transaction.x_fecha_transaccion}
                        <br />
                      </p>
                    </div>

                    <div className="content-column col-lg-6 col-md-12 col-sm-12">
                      <div className="inner-column wow fadeInLeft">
                        <h5>
                          Una vez la transacción se encuentre aprobada, en
                          máximo 24 horas recibirás el código de acceso a tu
                          correo electrónico o número de teléfono registrado.
                        </h5>
                        <b>
                          Recuerda que solo podrás usar el código cuando el
                          videojuego sea lanzado en Noviembre de 2021. A partir
                          de allí empezará a correr tu suscripción.
                        </b>
                        <br />
                        <br />
                        <div className="button-box text-center">
                          <a
                            href="https://www.endoforce.co/"
                            className="theme-btn btn-style-two text-light"
                          >
                            Volver
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        </Suspense>
      </div>
    </>
  );
};

export default Response;
